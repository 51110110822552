<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">{{loc.transactions}}</h1>
      </div>
<!--      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">-->
<!--        <button type="button" class="inline-flex items-center justify-center rounded-md border border-transparent bg-base-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-base-700 focus:outline-none focus:ring-2 focus:ring-base-500 focus:ring-offset-2 sm:w-auto">Export</button>-->
<!--      </div>-->
    </div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">ID</th>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{{loc.date}}</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{{loc.providers}}</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{{loc.invoices}}</th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <p>{{loc.status}}</p>
                </th>
              </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-for="transaction in transactions" :key="transaction.email">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ transaction.id }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ transaction.created_at }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div v-for="provider in transaction.providers">
                    <p>{{provider}}</p>
                  </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div v-for="invoice in transaction.invoice">
                    <a :href="invoice.file" target="_blank">
                      <DocumentIcon class="w-4 h-4 hover:text-base-500" />
                    </a>
                  </div>
                </td>
                <td class="whitespace-nowrap flex justify-center px-3 py-4 text-sm text-gray-500">
                  <ClockIcon v-if="!transaction.finished" class="w-8 h-8 text-yellow-600"/>
                  <CheckCircleIcon v-else class="w-8 h-8 text-green-600"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
// const transactions = [
//   { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
//   // More transactions...
// ]
import {DocumentIcon, ClockIcon, CheckCircleIcon} from "@heroicons/vue/solid"

export default {
  components: {
    DocumentIcon,
    ClockIcon,
    CheckCircleIcon
  },
  data() {
    return {
      transactions: []
    }
  },
  async mounted() {
    console.log(this.loc)
    let {data} = await this.axios.get(`v2/transactions/`)
    this.transactions = data
  }
}
</script>